/*==========================
  VARIABLES
==========================*/
:root {
  --color-background-root: #fbfaff;
  --color-text: #161520;
  --color-dot: #8f8c96;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background-root: #161520;
    --color-text: #fbf8ff;
  }
}

@font-face {
  font-family: "averta-bold";
  src: url("fonts/averta-bold.woff2") format("woff2"),
    url("fonts/averta-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "averta-semibold";
  src: url("fonts/averta-semibold.woff2") format("woff2"),
    url("fonts/averta-semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*==========================
  CSS RESET
 ==========================*/
*,
:after,
:before {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  overflow-x: hidden;
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: averta-bold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 100%;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background: var(--color-background-root);
  display: flex;
  justify-content: center;
}

h1 {
  font-family: averta-bold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h2 {
  font-family: averta-semibold, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

/*==========================
  TOP
 ==========================*/
.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  max-width: 1024px;
  max-height: 1366px;
  animation-name: fade;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.top {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: calc(50vh - 20px);
  align-items: center;
}

/*==========================
  TITLE
 ==========================*/
.top h1 {
  color: var(--color-text);
  font-weight: 300;
  font-size: 5.2em;
}

@media (max-width: 767px) {
  .top h1 {
    font-size: 4.6em;
  }
}

@media (max-width: 410px) {
  .top h1 {
    font-size: 3.8em;
  }
}

.top h1 .dot {
  color: var(--color-dot);
}

/*==========================
  BOTTOM
 ==========================*/
.bottom {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: calc(50vh - 20px);
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .bottom {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
  }
}

.bottom h2 {
  max-width: 800px;
  opacity: 0.9;
  color: var(--color-text);
  font-size: 2em;
  font-weight: 200;
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
}

@media (max-width: 767px) {
  .bottom h2 {
    font-size: 1.6em;
  }
}

@media (max-width: 410px) {
  .bottom h2 {
    font-size: 1.3em;
  }
}

/*==========================
  VIDEO
 ==========================*/
.preview-clip {
  width: 240px;
}

.preview-clip-light-mode {
  display: flex;
}

.preview-clip-dark-mode {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .preview-clip-light-mode {
    display: none;
  }
  .preview-clip-dark-mode {
    display: flex;
  }
}

@media (max-width: 767px) {
  .preview-clip {
    display: none;
  }
}

/*==========================
  BADGES
 ==========================*/

.badges {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
}

.badges picture {
  margin: 10px;
}

.badges picture:first-child {
  margin-left: 0px;
}

.badges img {
  max-height: 50px;
  border-radius: 5px;
  transition: box-shadow 200ms;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 10px;
}

@media (max-width: 414px) {
  .badges img {
    max-height: 40px;
  }
}

.badges img:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.badges img:active {
  transform: scale(0.98);
}

.bottom h3 {
  text-align: center;
  line-height: 42px;
  font-weight: 300;
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--color-text-bottom);
}

@media (max-width: 414px) {
  .bottom h3 {
    font-size: 1.3rem;
  }
}

@media (max-width: 374px) {
  .bottom h3 {
    font-size: 1.1rem;
  }
}

/*==========================
  GITHUB CORNER
 ==========================*/
.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

.github-corner svg {
  color: var(--color-background-root);
  fill: var(--color-text);
}
